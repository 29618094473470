<template>
    <div>
        <div class="d-flex justify-space-between align-center mb-6">
            <div style="width: 100px">
                <v-autocomplete
                        v-model="lang"
                        :rules="[() => !!lang || 'This field is required']"
                        :items="languages"
                        label="Lang"
                        required
                        solo
                        hide-details
                        dense
                />
            </div>
            <v-btn
                    v-if="getInvestor"
                    color="primary"
                    elevation="2"
                    @click="openForm(getInvestor, createInvestorObject)"
            >update investor</v-btn>
            <v-btn
                    v-else
                    color="primary"
                    elevation="2"
                    @click="openForm(null, createInvestorObject)"
            >add investor</v-btn>
        </div>

        <v-card>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            Investor
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="font-weight-medium">Title</td>
                        <td>
                            {{
                            lang === "uz"
                            ? getInvestor.titleUz
                            : lang === "ru"
                            ? getInvestor.titleRu
                            : lang === "en"
                            ? getInvestor.titleEn
                            : ""
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-medium">Content</td>
                        <td v-html="lang === 'uz'
                            ? getInvestor.contentUz
                            : lang === 'ru'
                            ? getInvestor.contentRu
                            : lang === 'en'
                            ? getInvestor.contentEn
                            : ''
                        "
                        ></td>
                    </tr>
                    <tr>
                        <td class="font-weight-medium">Img</td>
                        <td>
                            <div class="service__img">
                                <img :src="baseURL + getInvestor.img"/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <Modal v-model="modal" width="1140px" title="Investor form">
            <Form
                v-if="modal"
                :data-object="dataObject"
                @close-modal="modal = false"
                @send-investor="sendInvestor"
            />
        </Modal>
    </div>
</template>

<script>
    import Form from "./Form";
    import formModal from "@/mixins/formModal";
    import deleteModal from "@/mixins/deleteModal";
    import { baseURL } from "@/services/api";
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "List",
        data: () => ({
            baseURL,
            lang: 'uz',
            languages: ['uz', 'ru', 'en'],
        }),
        components: { Form },
        computed: {
            ...mapGetters({
                getInvestor: 'getInvestor',
            })
        },
        mixins: [formModal, deleteModal],
        methods: {
            ...mapActions({
                fetchInvestor: 'fetchInvestor',
                updateInvestor: 'updateInvestor',
            }),

            createInvestorObject (investor = null) {
                const object = {
                    titleUz: investor ? investor.titleUz : '',
                    titleRu: investor ? investor.titleRu : '',
                    titleEn: investor ? investor.titleEn : '',
                    contentUz: investor ? investor.contentUz : '',
                    contentRu: investor ? investor.contentRu : '',
                    contentEn: investor ? investor.contentEn : '',
                };
                if (investor === null) object.img = null;
                return object;
            },

            async sendInvestor(data) {
                await this.updateInvestor(data);

                await this.fetchInvestor();
                this.modal = false;
            },
        },
        async mounted() {
            await this.fetchInvestor();
        }
    }
</script>

<style scoped lang="scss">
    .service__img {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        overflow: hidden;

        & img {
            height: 100px;
        }
    }
</style>
