<template>
    <div>
        <v-form @submit.prevent="submit">
            <v-text-field v-model="dataObject.titleUz" label="titleUz" clearable />

            <v-text-field v-model="dataObject.titleRu" label="titleRu" clearable />

            <v-text-field v-model="dataObject.titleEn" label="titleEn" clearable />

            <v-text-field
                    v-model="dataObject.contentUz"
                    label="contentUz"
                    clearable
            />

            <v-text-field
                    v-model="dataObject.contentRu"
                    label="contentRu"
                    clearable
            />

            <v-text-field
                    v-model="dataObject.contentEn"
                    label="contentEn"
                    clearable
            />

            <v-file-input
                    v-model="dataObject.img"
                    accept="image/*"
                    label="Image"
            />

            <v-row no-gutters>
                <v-spacer />
                <v-btn @click="closeModal" color="primary" text class="mr-3"
                >Cancel</v-btn
                >
                <v-btn type="submit" color="primary" depressed>Save</v-btn>
            </v-row>
        </v-form>
    </div>
</template>

<script>
    import {baseURL} from "@/services/api";
    import {createFormData} from "@/services/validationRules";
    import Editor from 'ckeditor5-custom-build/build/ckeditor';
    import TokenService from "@/services/token";
    export default {
        props: {
            dataObject: Object
        },
        name: "Form",
        data: () => ({
            editor: Editor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: baseURL + "image/upload",
                    withCredentials: false,
                    headers: {
                        Authorization: `${TokenService.getToken()}`
                    }
                }
            }
        }),
        methods: {
            submit() {
                this.$emit('send-investor', createFormData(this.dataObject))
            },
            closeModal() {
                this.$emit('close-modal');
            }
        },
    }
</script>

<style scoped>

</style>
