var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center mb-6"},[_c('div',{staticStyle:{"width":"100px"}},[_c('v-autocomplete',{attrs:{"rules":[function () { return !!_vm.lang || 'This field is required'; }],"items":_vm.languages,"label":"Lang","required":"","solo":"","hide-details":"","dense":""},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}})],1),(_vm.getInvestor)?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":function($event){return _vm.openForm(_vm.getInvestor, _vm.createInvestorObject)}}},[_vm._v("update investor")]):_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":function($event){return _vm.openForm(null, _vm.createInvestorObject)}}},[_vm._v("add investor")])],1),_c('v-card',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Investor ")]),_c('th')])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"font-weight-medium"},[_vm._v("Title")]),_c('td',[_vm._v(" "+_vm._s(_vm.lang === "uz" ? _vm.getInvestor.titleUz : _vm.lang === "ru" ? _vm.getInvestor.titleRu : _vm.lang === "en" ? _vm.getInvestor.titleEn : "")+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-medium"},[_vm._v("Content")]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.lang === 'uz'
                        ? _vm.getInvestor.contentUz
                        : _vm.lang === 'ru'
                        ? _vm.getInvestor.contentRu
                        : _vm.lang === 'en'
                        ? _vm.getInvestor.contentEn
                        : ''
                    )}})]),_c('tr',[_c('td',{staticClass:"font-weight-medium"},[_vm._v("Img")]),_c('td',[_c('div',{staticClass:"service__img"},[_c('img',{attrs:{"src":_vm.baseURL + _vm.getInvestor.img}})])])])])]},proxy:true}])})],1),_c('Modal',{attrs:{"width":"1140px","title":"Investor form"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.modal)?_c('Form',{attrs:{"data-object":_vm.dataObject},on:{"close-modal":function($event){_vm.modal = false},"send-investor":_vm.sendInvestor}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }